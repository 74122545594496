<template>
  <el-container class="layout-container">
    <el-aside style="width: 240px" v-if="!isQiankun">
      <sidebar />
    </el-aside>
    <el-container>
      <el-header class="layout-header" v-if="!isQiankun">
        <navbar />
      </el-header>
      <el-main class="layout-content">
        <BreadCrumb
          v-if="isShowBreadRouters.includes(routeRow.paths)"
          :routeList="routeRow"
        />
        <app-main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from './Sidebar';
import AppMain from './AppMain.vue';
import Navbar from './Navbar.vue';
import BreadCrumb from '@/components/Breadcrumd.vue';
export default {
  name: 'Layout',
  components: {
    Sidebar,
    AppMain,
    Navbar,
    BreadCrumb,
  },
  data() {
    return {
      inlineCollapsed: true,
      collapsed: false,
      isQiankun: false,
      isShowBreadRouters: [
        '/opermanage/businesswrelist/:id',
        '/opermanage/businesscardsld/:id',
        '/opermanage/couponlist/:merchantId',
        '/opermanage/writeofflist/:merchantId/:merchantName',
        '/opermanage/shoplist/:merchantId/:merchantName',
        '/opermanage/sharecardlist/:merchantId/:merchantName',
      ],
      routeList: {},
    };
  },
  computed: {
    routeRow() {
      const routerRow =
        this.$route.matched.length > 0
          ? this.$route.matched[this.$route.matched.length - 1]
          : {};
      const params = {
        paths: routerRow.path,
        name: routerRow.name,
      };
      return params.paths ? params : {};
    },
  },
  created() {
    this.isQiankun = window.__POWERED_BY_QIANKUN__;
  },
};
</script>
<style lang="less" scoped>
.layout-container {
  height: 100%;
  .layout-header {
    height: 100%;
    padding: 0 20px;
    background-color: #244575;
  }
  .layout-content {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
  }
}
</style>
