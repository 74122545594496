<template>
  <el-col :span="colspan">
    <div class="search-item-wrapper">
      <div
        class="item-label"
        v-if="label"
        :style="{
          'min-width': typeof width === 'string' ? width : width + 'px',
          textAlign: align,
        }"
      >
        {{ label }}
      </div>
      <div class="item-container">
        <slot />
      </div>
    </div>
  </el-col>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    colspan: {
      type: [String, Number],
      default: 6,
    },
    align: {
      type: String,
      default: 'right',
    },
  },
};
</script>
<style lang="scss">
.search-item-wrapper {
  display: flex;
  align-items: center;
  margin: 0 20px 16px 0;
  // float: left;
  // display: inline-flex;
  width: calc(100% - 20px);
  .item-container {
    flex: auto;
    width: calc(100% - 20px);
    .el-select,
    .el-date-editor {
      width: 100%;
      .el-input {
        width: 100%;
      }
    }
  }
  .item-label {
    // width:90px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 15px;
  }
}
</style>
