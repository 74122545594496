import dayjs from 'dayjs';
import _this from '../main';
/* 下载/导出
 * @param data
 */
export function downloadXlsx(data: any) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', 'excel.xls');

  document.body.appendChild(link);
  link.click();
}

export function Nonchinese(s: any) {
  s = s.replace(/[\u4e00-\u9fa5\d]/g, '');
  return s;
}
/* 下载 图片
 * @param data
 */
export function downpicture(data: any) {
  const link = document.createElement('a');
  link.href = data;
  link.download = '门店核销二维码.jpg';
  link.click();
}

/**
 * 将数据数据解构成list
 * @param treeList
 */
export function treeToList(treeList: any[]): any[] {
  return treeList.reduce((list, item) => {
    if (item.children) {
      list = list.concat(treeToList(item.children));
    }
    list.push({ ...item, children: null });
    return list;
  }, []);
}

export function getFormatTime(value: string) {
  if (value) {
    return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '';
  }
}

export function amountToFixedByDecimalDigits(
  amount: number | string,
  decimalDigits = 2,
) {
  const newAmount = Number(amount);
  if (typeof newAmount !== 'number' || typeof decimalDigits !== 'number')
    return NaN;
  return Number(newAmount.toFixed(decimalDigits));
}

// 节流函数
export function throttle(fn: any, interval = 2000) {
  let enterTime = 0; //触发的时间
  let gapTime = interval || 300; //间隔时间，如果interval不传，则默认300ms
  return () => {
    let backTime: any = new Date(); //第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      // eslint-disable-next-line prefer-rest-params
      fn.call(_this, arguments);
      enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}

// 防抖函数
export function debounce(fn: any, wait: number) {
  let timer: any;
  return () => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(_this, arguments);
    }, wait);
  };
}
