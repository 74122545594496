<template>
  <div class="byviewquery clearfix">
    <div class="content_search">
      <div class="view_search clearfix">
        <slot name="search"></slot>
      </div>
      <div class="view_operation clearfix">
        <el-button
          size="small"
          type="primary"
          class="el-icon-search"
          @click="handleSearch"
        >
          搜 索</el-button
        >
        <el-button
          type="danger"
          size="small"
          class="el-icon-refresh"
          @click="clear"
          plain
        >
          清 空</el-button
        >
      </div>
    </div>
    <div class="view_add clearfix">
      <slot name="add_operation"></slot>
    </div>
    <slot name="table" />
  </div>
</template>
<script>
export default {
  props: ['handleSearch', 'clear'],
};
</script>
<style lang="scss" scoped>
.byviewquery {
  width: 100%;
  .content_search {
    width: 100%;
    display: flex;
  }
  .view_search {
    width: calc(100% - 160px);
  }
  .view_operation {
    white-space: nowrap;
  }
  .view_add {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
