export default {
  state: {
    list: [],
  },
  mutations: {
    setData: (state: any, list: any[]) => {
      state.list = list;
    },
  },
  actions: {
    fetchData({ commit }: any) {
      commit('setData', []);
    },
  },
};
