import request from '../utils/request';

export const LoginApi = {
  login(params: any) {
    return request.post('/scAdmin/admin/auth/pwd/login', params);
  },
  getUserInfo() {
    return request.get('/scAdmin/admin/auth/userInfo');
  },
};
