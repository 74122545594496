<template>
  <div v-if="!isHidden(item)">
    <router-link v-if="!isHasChild(item)" :to="item.path">
      <el-menu-item :index="item.path">
        <span>{{ item.name }}</span>
      </el-menu-item>
    </router-link>
    <el-submenu v-else :index="item.path">
      <span slot="title">
        <span>{{ item.name }}</span>
      </span>
      <sidebar-item
        v-for="child in item.children"
        :item="child"
        :key="child.path"
      />
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    isHidden(route) {
      return route?.meta?.hidden;
    },
    isHasChild(route) {
      return Boolean(route?.children?.length);
    },
  },
};
</script>
