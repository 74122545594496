<template>
  <div class="my-AMap">
    <div id="tip">
      <input placeholder="请输入地址" type="text" id="keyword" name="keyword" />
    </div>
    <div id="mapContainer"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: '',
    };
  },
  methods: {},
  mounted() {
    const map = new AMap.Map('mapContainer', {
      resizeEnable: true,
      zoom: 13, //地图显示的缩放级别
      keyboardEnable: false,
    });
    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
      const autoOptions = {
        input: 'keyword', //使用联想输入的input的id
      };
      const autocomplete = new AMap.Autocomplete(autoOptions);
      const placeSearch = new AMap.PlaceSearch({
        map: map,
      });
      AMap.event.addListener(autocomplete, 'select', e => {
        //TODO 针对选中的poi实现自己的功能
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name);
        this.$emit('postPosition', e.poi.location);
      });
      AMap.event.addListener(placeSearch, 'markerClick', e => {
        this.$emit('postPosition', e.data.location);
        // _that.saveLatLng = e.data.location;
        // _that.sbAddress = "";
        // infoWindow.setContent(createContent(e.data, {}));
        // infoWindow.open(map, e.data.location); //开启信息窗体
      });
    });
    // map.on('click', e => {
    //   console.log(663, e);
    // });
  },
};
</script>
<style lang="scss" scoped>
.my-AMap {
  margin-bottom: 150px;
  #tip {
    z-index: 555;
    background-color: #ddf;
    color: #333;
    border: 1px solid silver;
    box-shadow: 3px 4px 3px 0px silver;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    overflow: hidden;
    line-height: 20px;
    input[type='text'] {
      height: 25px;
      border: 0;
      padding-left: 5px;
      width: 280px;
      border-radius: 3px;
      outline: none;
    }
  }
  #mapContainer {
    overflow: hidden;
    // margin-top: 10px;
    width: 100%;
    height: 190px;
    margin-bottom: 50px;
  }
}
</style>
<style>
.amap-sug-result {
  z-index: 999999 !important;
}
</style>
