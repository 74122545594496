<template>
  <div>
    <el-table
      :show-summary="showSummary"
      :summary-method="getSummaries"
      ref="tableRef"
      :data="tableData"
      :row-key="rowKey"
      :height="height"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      :span-method="spanMethod"
      empty-text="未找到相关内容，请重新输入查询条件"
      border
      :header-cell-style="{
        background: '#F7F9FB',
        color: '#000000',
      }"
    >
      <slot />
    </el-table>
    <el-pagination
      v-if="pagination"
      :style="{ 'text-align': pagtionalign, 'margin-top': '20px' }"
      :total="total"
      background
      :page-size="size"
      :page-sizes="[10, 20, 50]"
      :current-page.sync="currentPage"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handlePageChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Array,
    },
    totalSize: {
      type: Number,
      default: 0,
    },
    currentNumber: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
    },
    rowKey: [String, Function],
    autoLoad: {
      type: Boolean,
      default: true,
    },
    spanMethod: {
      type: Function,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    api: {
      type: Function,
    },
    apiParams: [Object, Array],
    pagtionalign: {
      type: String,
      default: 'right',
    },
    cellmouse: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    getSummaries: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      size: this.pageSize,
      tableData: this.dataSource,
      currentPage: 1,
    };
  },
  watch: {
    dataSource(data) {
      this.tableData = data;
      this.total = this.totalSize;
      this.currentPage = this.currentNumber;
    },
  },
  mounted() {
    if (this.api && this.autoLoad) {
      this.onLoad();
    }
  },
  methods: {
    clearSelection() {
      this.$refs['tableRef'].clearSelection();
    },
    handleSelectionChange(selections) {
      this.$emit('selection-change', selections);
    },
    handleRefresh() {
      this.onLoad(this.currentPage);
    },
    handlePageChange(page) {
      this.$emit('current-change', page);
      this.onLoad(page);
    },
    handleSizeChange(val) {
      this.size = val;
      this.$emit('size-change', val);
      this.onLoad();
    },
    onLoad(page = 1) {
      this.loading = true;
      this.api({
        currentPage: page,
        pageSize: this.size,
        ...this.apiParams,
      })
        .then(res => {
          if (!res.data) {
            this.tableData = [];
          }
          if (res.data && res.data.records) {
            res.data.records.forEach(item => {
              item.distributionStatus = item.distributionStatus === '1';
            });
            this.tableData = res.data.records;
            this.total = res.data.totalSize;
            this.currentPage = res.data.currentPage;
          } else if (res.records) {
            this.tableData = res.records || [];
            this.total = res.totalSize;
            this.currentPage = res.currentPage;
          } else if (res.data) {
            this.tableData = res.data || [];
            this.total = res.totalSize;
            this.currentPage = res.currentPage;
          }
          // this.$refs.tableRef.doLayout();
        })
        .catch(() => {
          this.tableData = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItemByIndex(index) {
      this.tableData.splice(index, 1);
      this.total -= 1;
    },
    updateItemByIndex(index, fn) {
      fn(this.tableData[index]);
    },
    // hoverTip(row, column) {
    //   if (column.label === '优惠券') {
    //     this.$emit('cell-mouse-enter', row);
    //   }
    // },
    // leaveTip(row, column) {
    //   if (column.label === '优惠券') {
    //     this.$emit('cell-mouse-leave', row);
    //   }
    // },
  },
};
</script>

<style></style>
