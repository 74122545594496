<template>
  <div class="sidebar">
    <div class="logo">
      <img src="@/assets/logo.png" alt="logo" />
      <span>珊瑚共享卡管理系统</span>
    </div>
    <el-menu
      class="side-menu"
      mode="vertical"
      text-color="#fff"
      background-color="#244575"
      active-text-color="#fff"
      :default-active="selectedKeys"
    >
      <sidebar-item v-for="menu in routes" :key="menu.path" :item="menu" />
    </el-menu>
  </div>
</template>

<script>
import SidebarItem from './SidebarItem.vue';
import { mapState } from 'vuex';
export default {
  name: 'Sidebar',
  components: {
    SidebarItem,
  },
  data() {
    return {
      inlineCollapsed: false,
    };
  },
  computed: {
    // routes() {
    //   return this.$router.options.routes;
    // },
    selectedKeys() {
      return this.$route.path;
    },
    ...mapState({
      routes: state => state.addRouters,
    }),
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  height: 100%;
  overflow: auto;
  background-color: #244575;
  text-align: left;
  .side-menu {
    border-right: none;
    font-weight: bold;
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  img {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
