import { RouteConfig } from 'vue-router';
/**
 * 过滤动态路由
 */
export function createMenus(
  menus: any[],
  routers: RouteConfig[],
): RouteConfig[] {
  return menus.map(menu => {
    if (menu?.children?.length) {
      menu.children = createMenus(menu.children, routers);
    }
    const router: any = routers.find(item => item.path === menu.component);
    // 目录
    // if (menu.type === MENU_TYPE.DIR) {
    //   return {
    //     name: menu.name,
    //     path: menu.href,
    //     component: router?.component,
    //     children: menu.children,
    //     meta: { icon: menu.icon },
    //   };
    // }
    // 菜单
    if (menu.type === 'MENU') {
      return {
        name: menu.name,
        path: menu.component || '/system',
        component: router?.component,
        children: menu?.children || [],
        meta: { hidden: menu.visible === false },
      };
    }
    // 按钮
    return {
      name: menu.name,
      path: menu.component,
      meta: { hidden: true },
    };
  });
}
