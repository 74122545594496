
































import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { Nonchinese } from '@/utils/tools';
import request from '@/utils/request';
import { Message } from 'element-ui';
import { isNumber } from '@/constants/mertmanget';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const AddDialog = ref();
    const AddForm = ref();
    const AddData = reactive({
      rePassword: '',
      newPassword: '',
      userId: '',
    });
    const validDays = (rule: any, value: any, callback: any) => {
      if (AddData.newPassword !== value) {
        callback(new Error(`密码不一致`));
      } else {
        callback();
      }
    };
    const state = reactive({
      AddData,
      MerchantList: [],
      isshowshop: false,
      Formrule: {
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        rePassword: [
          { required: true, message: '请输入确认密码', trigger: 'change' },
          { validator: validDays },
        ],
      },
    });
    return {
      ...toRefs(state),
      Nonchinese,
      AddDialog,
      AddForm,
      isNumber,
      dialoginit: (obj: any) => {
        AddData.userId = obj.id;
        AddDialog.value.open();
        // const AddInfo: AddParam = obj;
        nextTick(() => {
          AddForm.value.resetFields();
        });
        // state.AddData = AddInfo ? copy(AddInfo) : {};
      },
      finishkeep: () => {
        AddForm.value.validate(async (val: boolean) => {
          if (!val) return;
          await request.post('/scAdmin/admin/verifierMember/resetPwd', {
            userId: AddData.userId,
            newPassword: AddData.newPassword,
          });
          AddForm.value.resetFields();
          Message.success('保存成功');
          emit('confirm');
          AddDialog.value.dialogFormVisibles = false;
        });
      },
    };
  },
});
