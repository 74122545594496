import { RouteConfig } from 'vue-router';
import Layout from '../../layout/index.vue';

export const operManget: RouteConfig = {
  path: '/opermanage',
  component: Layout,
  name: '运营管理',
  children: [
    {
      path: '/opermanage/businesslist',
      name: '商户列表',
      component: () =>
        import(/* webpackChunkName: "about" */ './Business/list.vue'),
    },
    {
      path: '/opermanage/businesswrelist/:id',
      name: '核销统计',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './Business/writeofflist.vue'),
    },
    {
      path: '/opermanage/businesscardsld/:id',
      name: '售卡记录',
      meta: { hidden: true },
      component: () =>
        import(
          /* webpackChunkName: "about" */ './Business/cardsalesrecord.vue'
        ),
    },
    {
      path: '/opermanage/sharecardlist',
      name: '共享卡管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './ShareCard/list.vue'),
    },
    {
      path: '/opermanage/sharecardlist/:merchantId/:merchantName',
      name: '共享卡管理',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './ShareCard/list.vue'),
    },
    {
      path: '/opermanage/shardcardadd',
      name: '添加新卡',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './ShareCard/add.vue'),
    },
    {
      path: '/opermanage/shardcardadd/:id',
      name: '编辑新卡',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './ShareCard/add.vue'),
    },
    {
      path: '/opermanage/shardcardsale/:id/:merchantId',
      name: '售卡页面配置',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './ShareCard/cardsalespage.vue'),
    },
    {
      path: '/opermanage/couponlist',
      name: '优惠券管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './Coupon/list.vue'),
    },
    {
      path: '/opermanage/couponlist/:merchantId',
      name: '优惠券管理',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './Coupon/list.vue'),
    },
    {
      path: '/opermanage/couponadd',
      name: '添加优惠券',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './Coupon/add.vue'),
    },
    {
      path: `/opermanage/couponadd/:id/:type`,
      name: '优惠券详情',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './Coupon/add.vue'),
    },
    {
      path: `/opermanage/iscoupons/list`,
      name: '发券管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './IsCoupons/list.vue'),
    },
    {
      path: '/opermanage/shoplist',
      name: '门店管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './Shop/list.vue'),
    },
    {
      path: '/opermanage/shoplist/:merchantId/:merchantName',
      name: '门店管理',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './Shop/list.vue'),
    },
    {
      path: '/opermanage/useragret/list',
      name: '用户协议及隐私政策',
      component: () =>
        import(/* webpackChunkName: "about" */ './UserAgret/list.vue'),
    },
    {
      path: '/opermanage/hotcity/list',
      name: '热门城市',
      component: () =>
        import(/* webpackChunkName: "about" */ './HotCity/list.vue'),
    },
    {
      path: '/opermanage/writeofflist',
      name: '成员管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './WriteOff/list.vue'),
    },
    {
      path: '/opermanage/writeofflist/:merchantId/:merchantName',
      name: '成员管理',
      meta: { hidden: true },
      component: () =>
        import(/* webpackChunkName: "about" */ './WriteOff/list.vue'),
    },
    {
      path: '/opermanage/tabmanagelist',
      name: '页签管理',
      component: () =>
        import(/* webpackChunkName: "about" */ './TabManage/list.vue'),
    },
  ],
};
