import { Notification } from 'element-ui';
import axios, { AxiosError } from 'axios';
// import microActions from '@/qiankun/actions';
import { downloadXlsx } from './tools';
// 创建 axios 实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
});
// token 格式错误/过期
const TOKEN_ERROR_CODE = [401, '401', 'GATE_0019', 'GATE_0005', 'COMMON_001'];
// const globalThis = window as any;

// 异常拦截处理器
const errorHandler = (error: AxiosError) => {
  if (error.response) {
    const data = error.response.data;
    // 身份信息过期
    if (TOKEN_ERROR_CODE.includes(error.response.status)) {
      // if (globalThis.__POWERED_BY_QIANKUN__) {
      //   microActions.setGlobalState({ type: 'logout', payload: data.message });
      //   return Promise.reject(error);
      // }

      // 非乾坤框架中处理401
      if (window.location.pathname != '/login') {
        window.location.href = '/login';
      }
    }
    // 服务器错误提示
    Notification.error({
      title: '提示',
      message: data.message || 'unknown error',
    });
  }
  return Promise.reject(error);
};

// 请求拦截器
request.interceptors.request.use(config => {
  const token = localStorage.getItem('ACCESS_TOKEN');

  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
}, errorHandler);

// 响应拦截器
request.interceptors.response.use(response => {
  if (response.request.responseType === 'blob') {
    if (response.data.code) {
      return Promise.resolve();
    }
    setTimeout(() => {
      downloadXlsx(response.data);
    }, 200);
    return Promise.resolve();
  }
  if (response.request.responseType === 'arraybuffer') {
    response.headers.qrcodesessionid &&
      localStorage.setItem('qrcodesessionid', response.headers.qrcodesessionid);
    return response.data;
  }
  if (response.data.code == 0) {
    return response.data;
  }
  return errorHandler({
    name: 'codeError',
    message: response.data.message,
    response: {
      ...response,
      status: response.data.code,
    },
    config: {},
    isAxiosError: true,
    toJSON: () => response.data,
  });
}, errorHandler);

export default request;
