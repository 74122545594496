import './core/lazy_use';
import 'element-ui/lib/theme-chalk/index.css';
import '@/components/index';
import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';
import VueAxios from 'vue-axios';

import App from './App.vue';
import router from './router';
import store from './store';
import axios from './utils/request';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCompositionAPI);
// *************** 乾坤子应用配置 ***************
let instance: any = null;
const globalThis = window as any;

function renderApp(props: any) {
  const { container } = props;
  instance = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

if (globalThis.__POWERED_BY_QIANKUN__) {
  // eslint-disable-next-line @typescript-eslint/camelcase
  __webpack_public_path__ = globalThis.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}

if (!globalThis.__POWERED_BY_QIANKUN__) {
  renderApp({ container: null });
}

// export async function bootstrap() {
//   console.log('coral-share-card-admin bootstraped');
// }

export async function mount(props: any) {
  renderApp(props);
}

export async function unmount() {
  if (instance) {
    instance.$destroy();
  }
}
export default instance; //李志超加 目的是为了其他ts文件可以访问Vue实例
// *************** 乾坤子应用配置 ***************
