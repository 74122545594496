import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '../layout/index.vue';
import { operManget as operMangetRoute } from '../views/operation-management/router';
import store from '@/store';
import { treeToList } from '@/utils/tools';
import { createMenus } from '@/utils/router';
import { Message, MessageBox } from 'element-ui';
import { distrManget as distrMangetRoute } from '../views/distribution-management/router';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/mertmanage',
    component: Layout,
    name: '商户管理',
    redirect: '/mertmanage/list',
    children: [
      {
        path: '/mertmanage/list',
        name: '商户管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/merchant-management/list.vue'
          ),
      },
      {
        path: '/mertmanage/databoard',
        name: '数据看板',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/merchant-management/databoard.vue'
          ),
      },
    ],
  },
  operMangetRoute,
  distrMangetRoute,
  {
    path: '/usermanage',
    component: Layout,
    name: '用户管理',
    children: [
      {
        path: '/usermanage/list',
        name: '用户管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/user-management/list.vue'
          ),
      },
      {
        path: '/usermanage/mainCard',
        name: '主卡用户管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/user-management/mainCard/list.vue'
          ),
      },
      {
        path: '/usermanage/viceCard',
        name: '副卡用户管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/user-management/viceCard/list.vue'
          ),
      },
    ],
  },
  {
    path: '/advermanage',
    component: Layout,
    name: '广告管理',
    children: [
      {
        path: '/advermanage/list',
        name: '广告管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/advertising-management/list.vue'
          ),
      },
    ],
  },
  {
    path: '/financial',
    component: Layout,
    name: '财务管理',
    children: [
      {
        path: '/financial/orderflow',
        name: '订单流水',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/financial-management/orderflow/list.vue'
          ),
      },
      {
        path: '/financial/withdalrevw',
        name: '提现审核',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/financial-management/withdalrevw.vue'
          ),
      },
      {
        path: '/financial/withdalset',
        name: '提现设置',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/financial-management/withdalset.vue'
          ),
      },
      {
        path: '/financial/payset',
        name: '支付设置',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/financial-management/payset.vue'
          ),
      },
    ],
  },
  {
    path: '/system',
    component: Layout,
    name: '系统设置',
    children: [
      {
        path: '/system/account/list',
        name: '账号管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/system-management/account/list.vue'
          ),
      },
      {
        path: '/system/role/list',
        name: '角色管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/system-management/role/list.vue'
          ),
      },
      {
        path: '/system/menu/list',
        name: '菜单管理',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/system-management/menu/list.vue'
          ),
      },
      {
        path: '/system/record/list',
        name: '操作日志',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/system-management/record/list.vue'
          ),
      },
    ],
  },
];

const constantsRoute: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { hidden: true },
    name: '共享卡管理平台',
    children: [
      {
        path: '/home',
        name: '共享卡管理平台',
        component: () =>
          import(/** webpackChunkName: "home" */ '@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { hidden: true },
  },
  {
    path: '*',
    component: () => import('@/views/404.vue'),
    meta: { hidden: true },
  },
];
const baseRouters = routes.concat(routes);
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {undefined
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch((err: any) => err)
// }
const router = new VueRouter({
  mode: 'history',
  base: (window as any).__POWERED_BY_QIANKUN__ ? '/csc/' : process.env.BASE_URL,
  routes: constantsRoute,
});
router.beforeEach(async (to, from, next) => {
  // 首先本地是否有token
  // let tokenStr;
  // if(to.path === '/login') {
  //   next()
  //   tokenStr = localStorage.getItem('ACCESS_TOKEN')
  // }
  if (to.path !== '/login') {
    // 本地无 token
    if (!localStorage.getItem('ACCESS_TOKEN')) {
      next({ path: '/login' });
      return;
    }
    // 已登录
    // 是否加载过路由
    if (store.getters.addRouters.length > 0 || store.getters.isAddRouters) {
      if (['/financial/withdalset', '/financial/payset'].includes(from.path)) {
        MessageBox.confirm(
          `是否确定离开当前页面？离开后当前页面正在编辑的信息将不会保存！`,
          {
            showClose: false,
            center: true,
          },
        )
          .then(() => {
            next();
          })
          .catch(() => {
            Message.info('取消了');
          });
        return;
      }
      next();
      return;
    }

    // 根据菜单构建路由
    const res = await store.dispatch('getUserInfo');

    if (res.data?.menus) {
      const allRouterList = treeToList(baseRouters);
      const routerMenus = createMenus(res.data?.menus, allRouterList);
      store.commit('setRouters', routerMenus);
      store.commit('setAddRouters', true);
      router.addRoutes(routerMenus);
      // for(let i = 0;i<routerMenus.length;i++){
      //   const element = routerMenus[i];
      //   console.log(element);

      //   router.addRoute(element);
      // }

      next({ path: to.path, replace: true });
    }
    return;
  }
  // 直接访问 /login
  localStorage.removeItem('ACCESS_TOKEN');
  next();
});
export default router;
