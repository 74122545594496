




































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    closeModal: {
      type: Boolean,
      default: true,
    },
    unBind: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '保 存',
    },
    canCelText: {
      type: String,
      default: '取 消',
    },
    title: {
      type: String,
      default: '标题',
    },
    width: {
      type: [String, Number],
      default: '500',
    },
    // 消息按钮 显示与否
    showclose: {
      type: Boolean,
      default: true,
    },
    // 确定按钮 显示与否
    showconfirm: {
      type: Boolean,
      default: true,
    },
    // 确认按钮 是否禁用
    confirmDisd: {
      type: Boolean,
      default: false,
    },
    // 取消按钮 是否禁用
    cancelDisd: {
      type: Boolean,
      default: false,
    },
    // 顶部 关闭icon 是否显示
    showcloseCel: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const dialogFormVisibles = ref(false);
    return {
      dialogFormVisibles,
    };
  },
  methods: {
    onConfirm() {
      // this.dialogFormVisibles = false;
      this.$emit('confirm');
    },
    onCancel() {
      this.dialogFormVisibles = false;
      this.$emit('cancel');
    },
    open() {
      this.dialogFormVisibles = true;
    },
  },
});
