<template>
  <div class="navbar">
    <el-dropdown class="dropdown-container" @command="handleCommand">
      <div class="account">
        <el-avatar :size="30" icon="user" class="avatar-wrapper" />
        <span class="account-name">{{ userName }}</span>
        <i class="el-icon-arrow-down el-icon--right" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item> 个人中心 </el-dropdown-item>
        <el-dropdown-item command="logout"> 退出登录 </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'Navbar',
  computed: {
    ...mapState(['userInfo']),
    userName() {
      return this.userInfo.username;
    },
  },
  methods: {
    ...mapActions(['logout']),
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  color: #fff;
  position: relative;
  overflow: hidden;
  height: 100%;
  .dropdown-container {
    display: flex;
    min-width: 110px;
    height: 100%;
    float: right;
    text-align: left;
    .avatar-wrapper {
      margin-right: 10px;
    }
    .account {
      display: flex;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
