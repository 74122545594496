import { Message } from 'element-ui';
import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import user from './modules/user';
import getters from './getters';
import { LoginApi } from '@/api/login';
import router from '@/router';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    addRouters: [],
    isAddRouters: false,
  },
  getters,
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setRouters(state, payload) {
      state.addRouters = payload;
    },
    setAddRouters(state, payload) {
      state.isAddRouters = payload;
    },
  },
  actions: {
    // 登录之后获取用户信息
    getUserInfo({ commit }) {
      return LoginApi.getUserInfo().then((res: any) => {
        if (res.success) {
          commit('setUserInfo', res.data?.userAccount);
          localStorage.setItem(
            'user_info',
            JSON.stringify(res.data?.userAccount),
          );
        }
        return res;
      });
    },
    // 登录
    login(_, params) {
      return LoginApi.login(params).then(res => {
        if (res?.data?.accessToken) {
          localStorage.setItem('ACCESS_TOKEN', res.data.accessToken);
          return res;
        }
        return Promise.reject();
      });
    },
    // 登出
    logout({ commit }, payload) {
      if (payload) Message.warning(payload);
      localStorage.clear();
      setTimeout(() => {
        commit('setRouters', []);
        commit('setAddRouters', false);
      }, 200);
      router.replace('/login');
      // window.history.pushState(null, '', '/login');
    },
  },
  modules: {
    app,
    user,
  },
});
