import { RouteConfig } from 'vue-router';
import Layout from '../../layout/index.vue';

export const distrManget: RouteConfig = {
  path: '/distribution',
  component: Layout,
  name: '分销管理',
  children: [
    {
      path: '/distribution/merchants',
      name: '分销商户列表',
      component: () =>
        import(/* webpackChunkName: "about" */ './merchants/list.vue'),
    },
    {
      path: `/distribution/setting/:merchantId`,
      name: '分销设置',
      component: () =>
        import(/* webpackChunkName: "about" */ './merchants/setting.vue'),
    },
    {
      path: '/distribution/merchantUser',
      name: '分销员列表',
      component: () =>
        import(/* webpackChunkName: "about" */ './merchantUser/list.vue'),
    },
  ],
};
