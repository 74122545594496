<template>
  <div class="breadcrumb_layout">
    <transition name="fade-transform" mode="out-in">
      <el-breadcrumb separator="/" style="margin-bottom: 20px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/opermanage/businesslist' }"
          >商户列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>
          {{ routeList.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </transition>
    <!-- <h3>{{ title }}</h3> -->
  </div>
</template>

<script>
export default {
  props: {
    routeList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      BreadRouteList: [
        {
          path: '/opermanage/businesslist',
          name: '商户列表',
        },
      ],
    };
  },
  computed: {
    title() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb_layout {
  .el-breadcrumb {
    font-size: 15px;
    line-height: 35px;
  }

  h3 {
    margin: 15px 0 30px 0;
  }
}
</style>
