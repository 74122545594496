




































import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { Nonchinese } from '@/utils/tools';
import { Message } from 'element-ui';
import request from '@/utils/request';
import { isNumber } from '@/constants/mertmanget';
export default defineComponent({
  props: {
    isAdd: {
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const AddDialog = ref();
    const unBind = ref(false);
    const merchantUserId = ref('');
    const state = reactive({
      entityData: {
        realName: '',
        username: '',
        qrCode: '',
        nickName: '',
        avatarUrl: '',
      },
    });
    let timer: any;
    const createQrCode = async (params: any) => {
      const res: any = await request.post(
        '/scAdmin/admin/verifierMember/createQrCode',
        params,
        {
          responseType: 'arraybuffer',
        },
      );
      state.entityData.qrCode =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
    };
    const polling = async () => {
      return await request.get(
        `/scAdmin/admin/verifierMember/polling/${localStorage.getItem(
          'qrcodesessionid',
        )}`,
      );
    };
    const binding = async () => {
      return await request.get(
        `/scAdmin/admin/verifierMember/binding/${localStorage.getItem(
          'qrcodesessionid',
        )}`,
      );
    };
    const unBinding = async () => {
      await request.get(
        `/scAdmin/admin/verifierMember/unBinding/${merchantUserId.value}`,
      );
      Message.success('已经解绑');
      emit('confirm');
      AddDialog.value.dialogFormVisibles = false;
    };
    return {
      unBinding,
      cancel() {
        clearInterval(timer);
        localStorage.removeItem('qrcodesessionid');
      },
      ...toRefs(state),
      Nonchinese,
      AddDialog,
      unBind,
      isNumber,
      dialoginit: async (obj: any) => {
        unBind.value = obj.bindingWechat;
        merchantUserId.value = obj.id;
        state.entityData = {
          realName: obj.realName,
          username: obj.username,
          qrCode: '',
          nickName: obj.nickName,
          avatarUrl: obj.avatar,
        };
        if (!obj.bindingWechat) {
          await createQrCode({
            merchantUserId: obj.id,
            qrConfig: {
              width: '300',
              height: '300',
            },
          });
          timer = setInterval(async () => {
            const res = await polling();
            if (res.data.code === '3' || res.data.code === '5') {
              clearInterval(timer);
              if (res.data.code === '3') {
                state.entityData.nickName = res.data.data.nickName;
                state.entityData.avatarUrl = res.data.data.avatarUrl;
              }
            }
          }, 2000);
        }
        AddDialog.value.open();
      },
      finishkeep: async () => {
        await binding();
        Message.success('绑定成功');
        emit('confirm');
        AddDialog.value.dialogFormVisibles = false;
      },
    };
  },
});
