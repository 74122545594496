// import { ActionContext } from 'vuex/types';

export default {
  state: {},
  mutations: {},
  actions: {
    // Login({ commit }: ActionContext<any, any>) {
    //   // TODO
    //   // console.log('暂时未登录...', commit);
    // },
  },
};
