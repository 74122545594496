/**
 * # 商户管理 账户状态
 */
export const Accountstatus = {
  '': '全部',
  0: '正常',
  2: '已到期',
};

export const Account = {
  '': '',
  0: '0',
  2: '2',
};
/**
 * # 商户管理 账户状态列表
 */
// type statusType = keyof typeof Accountstatus;
export const AccountStatusList = Object.keys(Account).map(item => ({
  value: item,
  label: (Accountstatus as any)[item],
}));

/**
 *商户列表搜索参数
 */
export interface SearchParam {
  mobile?: string; //手机号
  name?: string; // 商户名称
  status?: string; // 账户状态
  startTime?: string; //开始日期
  endTime?: string; //结束日期
}

/**
 * 开户添加参数
 */
export interface AddParam {
  id?: string;
  name?: string;
  shortName?: string;
  logoImg?: string;
  contacts?: string;
  mobile?: string;
  DateTime?: any;
  startTime?: string;
  endTime?: string;
  packagePrice?: string | number;
}

type _typeObj = { [anyKey: string]: any };

/**
 * @param copy 深拷贝
 * @param _object 如果不传返回为空对象 必须是js的{}对象
 * @param _obj 可选 返回传入的@param _object 必须是js的{}对象,
 */
export const copy = (_object: _typeObj, _obj: _typeObj = {}): _typeObj => {
  if (!(Object.prototype === Object.getPrototypeOf(_object))) {
    return new Error('传入参数***_object***类型错误');
  }
  for (const key in _object) {
    if (Object.prototype === Object.getPrototypeOf(_object[key])) {
      _obj[key] = copy(_object[key]);
    } else {
      _obj[key] = _object[key];
    }
  }
  return _obj;
};

/**
 * 金额 分换算元
 * @param {*} s
 */
export const scale = (s: any) => {
  return Number(s) / 100;
};

/**
 * 金额 分换算元
 * @param {*} s
 */
export const Yscale = (s: any) => {
  return Number(s) * 100;
};

/**
 * 排序 只为数字
 * @param {*} n
 */
export const isNum = (n: any) => {
  n = n.replace(/[^\d.]/g, '');
  return n;
};

/**
 * 排序 只为数字且不能输入小数
 * @param {*} n
 */
export const isNums = (n: any) => {
  n = n.replace(/\D/g, '');
  return n;
};

/**
 * 只为数字和英文
 * @param {*} s
 */
export function isNumber(s: any) {
  s = s.replace(/[\W]/g, '');
  return s;
}

/**
 * 只为数字且保留一位小数
 * @param {*} value
 */
export const NoNum = (value: any) => {
  //先把非数字的都替换掉，除了数字和.
  //  if(!value.indexOf('.')) return;
  value = value.replace(/[^\d.]/g, '');
  //保证只有出现一个.而没有多个.
  value = value.replace(/\.{2,}/g, '.');
  //必须保证第一个为数字而不是.
  value = value.replace(/^\./g, '');
  //保证.只出现一次，而不能出现两次以上
  value = value
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.');
  //只能输入两个小数
  value = value.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, '$1');
  return value;
};
